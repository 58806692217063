const zh = {
  common: {
    i_loading: "拼命加载中。。。",
    i_back: "返回",
    i_timedOut: "请求超时，请检查网络",
    i_copy: "复制成功",
    i_cancel: "取消",
    i_goBack: "立即返回"
  },
  index: {
    i_coinsLog: "金币记录",
    i_totalCoins: "你已积累的财富",
    i_convert: "兑换",
    i_convertMsg: "金币换现金",
    i_inviteFriends: "邀请好友",
    i_inviteFriendsMsg: "领取更多金币",
    i_inviteCode: "邀请码",
    i_accountC: "账户中心",
    i_account: "账号",
    i_leaderboard: "排行榜",
    i_contact: "联系我们",
    i_browserOpen: "请拷贝至默认浏览器打开",
    i_language: "zh"
  },
  convert: {
    i_convert: "兑换",
    i_convertLog: "兑换记录",
    i_convertT: "将你的金币换成现金",
    i_choose: "请选择兑换方式",
    i_input: "输入",
    i_account: "收款账号",
    i_editAccount: "修改账号",
    i_checkN: "输错号码概不负责",
    i_receivingName: "收款姓名",
    i_enterReceivingName: "输入收款姓名",
    i_select: "选择兑换货币",
    i_currency: "兑换货币",
    i_pleaseSelect: "请选择",
    i_bindAccount: "绑定收款账号",
    i_enterReceivingAccount: "输入收款账号",
    i_redeemNow: "立即兑换",
    i_bindN: "绑定后不可更改",
    i_bindNow: "立即绑定",
    i_following: "以下",
    i_bindT: "账户即将绑定为你的收款账户，绑定后不可更改，确定绑定吗？",
    i_bind: "确定绑定",
    i_changeNow: "立即更改",
    i_changeT:
      "申请修改需要等待管理员审核，审核期间将不能进行兑换，确认更改吗？",
    i_newReceivingAccount: "新的收款账号",
    i_modifyReceivingAccount: "修改收款账号",
    i_newReceivingName: "新的收款姓名",
    i_confirm: "确定更改",
    i_ok: "好的",
    i_convertMsg: "兑换申请已经提交，正在审核中",
    i_info_ing: "信息审核中",
    i_infoN: "信息审核失败",
    i_tips: "提示",
    i_activateMsg: "请先激活高级功能",
    i_accountN: "账号不能为空",
    i_nameN: "姓名不能为空",
    i_reviewSucceeded: "审核成功",
    i_underReview: "审核中",
    i_auditFailure: "审核失败",
    i_invalid: "无效",
    i_remark: "备注",
    i_checkConvertInfo: "请确认你的兑换信息"
  },
  inviteFriend: {
    i_inviteFriend: "邀请好友",
    i_inviteT: "每邀请一个好友",
    i_reward: "奖励",
    i_commissionT: "好友的提成",
    i_total: "总共",
    i_friends: "好友",
    i_text1: "好友通过你的链接下载APP",
    i_text2: "好友在APP内登陆/输入你的邀请码",
    i_text3_1: "好友玩游戏 -> 你获得",
    i_text3_2: "好友的金币",
    i_code: "邀请码",
    i_inviteNow: "立即邀请",
    i_enterCode: "填写邀请码",
    i_friendCode: "填写好友邀请码",
    i_code1: "只能填写1位好友邀请码",
    i_claimNow: "立即领取",
    i_inviteLog: "邀请记录",
    i_notActivated: "未升级宝箱",
    i_filledIn: "已填写",
    i_noLog: "暂无内容",
    i_newest: "最新",
    i_biggest: "最大",
    i_tipsDialogMsg: "你的好友需要通过点击你的链接下载app才能显示兑换入口 !!!",
    i_youHaveBeenInvited: "你已经被邀请了"
  },
  invite: {
    inviteImg: "zh",
    i_havaDownloaded: "是否已经下载了",
    i_downloaded: "已经下载",
    i_goDownload: "立即下载"
  },
  task: {
    i_task: "任务中心",
    i_taskMsg: "超多金币",
    i_dailyTasks: "每日任务",
    i_achievement: "成就任务",
    i_getNow: "立即领取",
    i_goNow: "立即前往",
    i_goAppGet: "请前往App领取",
    i_taskT: "任务"
  },
  leaderboard: {
    i_totalCoins: "总金币",
    i_friends: "好友"
  },
  inviteEvent: {
    i_received: "已领取",
    i_inviteNow: "立即邀请",
    i_Claim: "立即领取",
    i_invite: "邀请",
    i_friends: "个好友"
  },
  language: {
    zh: "中文",
    en: "English"
  }
};
export default zh;
