import Vue from "vue";
import VueRouter from "vue-router";
import PuzzleStoryInvite from "@/views/invite/PuzzleStoryInvite.vue";
import PaintingPuzzleInvite from "@/views/invite/PaintingPuzzleInvite.vue";
import JigsawItAllInvite from "@/views/invite/JigsawItAllInvite.vue";
import Convert from "@/views/Convert.vue";
import ConvertLog from "@/views/ConvertLog.vue";
import GoldLog from "@/views/GoldLog.vue";
import Index from "@/views/Index.vue";
import InviteFriend from "@/views/InviteFriend.vue";
import ConvertInfo from "@/views/ConvertInfo.vue";
import InviteLog from "@/views/InviteLog.vue";
import Task from "@/views/Task.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import ErrorPage from "@/views/ErrorPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index"
  },
  {
    path: "/puzzle-story/invite",
    name: "PuzzleStoryInvite",
    component: PuzzleStoryInvite
  },
  {
    path: "/painting-puzzle/invite",
    name: "PaintingPuzzleInvite",
    component: PaintingPuzzleInvite
  },
  {
    path: "/jigsaw-it-all/invite",
    name: "JigsawItAllInvite",
    component: JigsawItAllInvite
  },
  {
    path: "/convert",
    name: "convert",
    component: Convert
  },
  {
    path: "/convertLog",
    name: "convertLog",
    component: ConvertLog
  },
  {
    path: "/goldLog",
    name: "goldLog",
    component: GoldLog
  },
  {
    path: "/index",
    name: "index",
    component: Index
  },
  {
    path: "/inviteFriend",
    name: "inviteFriend",
    component: InviteFriend
  },
  {
    path: "/convertInfo/:id",
    name: "convertInfo",
    props: true,
    component: ConvertInfo
  },
  {
    path: "/inviteLog",
    name: "inviteLog",
    component: InviteLog
  },
  {
    path: "/task",
    name: "task",
    component: Task
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: Leaderboard
  },
  {
    path: "/errorPage",
    name: "errorPage",
    component: ErrorPage
  }
];

const router = new VueRouter({
  routes
});

export default router;
