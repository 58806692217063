<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-log-layout">
      <div class="cl-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('convert.i_convertLog')" @back="$router.push('/convert')" style="float: left;" />
        <div style="float: right;background-color: rgba(222, 231, 233, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img src="@/assets/common/goldIcon.png" style="width: 14px;vertical-align: middle;" />
          <span style="padding-right: 2px;font-size: 14px;color: black;text-decoration: none;vertical-align: middle;"> {{$store.state.goldNum}}</span>
        </div>
      </div>
      <div style="margin-top: 5%;margin-left: 5%;text-align: left;font-size: 12px;">*Please wait 7 working days patiently</div>

      <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;overflow-y: scroll;height: 83vh;">
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <div v-for="log in convertLog" :key="log.id" style="background-color: rgba(222, 231, 233, 1);margin-bottom: 3%;padding: 4%;border-radius: 8px;">
            <div style="overflow: hidden;">
              <div style="float: left;font-size: 22px;color: black;">
                {{log.price}}
              </div>
              <div style="float: right;">
                <span v-if="log.status == 1" style="color: black;">
                  {{$t('convert.i_reviewSucceeded')}}
                </span>
                <span v-else-if="log.status == 0" style="color: rgba(134, 122, 253, 1);">
                  {{$t('convert.i_underReview')}}
                </span>
                <span v-else-if="log.status == 2" style="color: red;">
                  {{$t('convert.i_auditFailure')}}
                </span>
                <span v-else-if="log.status == 3" style="color: red;">
                  {{$t('convert.i_invalid')}}
                </span>
              </div>
            </div>
            <div style="overflow: hidden;font-size: 13px;color: black;">
              <div style="float: left;margin-top: 2%;">
                {{log.cardName}}
              </div>
              <div style="float: right;margin-top: 2%;">
                {{log.time}}
              </div>
            </div>
            <div v-if="log.status == 2||log.status == 3" style="margin-top: 2%;text-align: left;font-size: 12px;color: red;">
              {{$t('convert.i_remark')}}：{{log.remark}}
            </div>
          </div>
        </div>

        <div v-if="convertLog==''">
          <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
          <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ convertLogParam.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        checkDialog: false,
        convertLog: [],
        busy: false,
        pageNum: 0,
        isLast: false,
        convertLogParam: {
          check: "",
          failMessage: "",
          appScheme: ""
        }
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      go() {
        if (this.convertLogParam.check == "login") {
          window.location.href = this.convertLogParam.appScheme + "login";
        } else {
          window.location.href = this.convertLogParam.appScheme;
        }
      },
      loadMore: function () {
        if (this.isLast != true) {
          this.busy = true;
          this.pageNum++;

          this.isLoading = true;
          this.$axios({
            url: "/webGift/convertLog",
            method: "get",
            params: {
              pageNum: this.pageNum,
              recordType: -1
            }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              result.data.data.log.forEach((element) => {
                this.convertLog.push(element);
              });
              this.isLast = result.data.data.isLast;
            }
          });
          this.busy = false;
        }
      }
    },
    created() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/convertLog",
        method: "get",
        params: {
          pageNum: 1,
          recordType: -1
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.$store.state.goldNum = result.data.data.goldNum;
        } else if (result.data.code == -2) {
          this.convertLogParam.check = result.data.data.check;
          this.convertLogParam.failMessage = result.data.message;
          this.convertLogParam.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    }
  };
</script>

<style>
  #convert-log-layout {
    width: 100%;
    height: 100%;
  }
  #convert-log-layout .cl-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
  }
</style>
